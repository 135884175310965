import React, { ReactElement, useEffect } from 'react';
import Head from 'next/head';
import { Box } from '@chakra-ui/react';
import { useLevo } from '@levo-so/react';
import { useAppDispatch } from '@/store';
import { login, logout } from '@/store/slices/userSlice';
import Navbar from './navbar/Navbar';
import Footer from './Footer';

interface Props {
  title?: string;
  description?: string;
  image?: string;
  ogTitle?: string;
  url?: string;
  children: ReactElement;
  keywords?: string;
  hideTopStrip?: boolean;
  hideFooter?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  title,
  description = 'IVCA',
  image = '/png/IVCA-Logo.png',
  url = null,
  ogTitle = null,
  keywords,
  hideTopStrip = true,
  hideFooter = false,
}) => {
  const dispatch = useAppDispatch();
  const { membership } = useLevo();

  const signIn = async () => {
    membership
      ?.getMe()
      .then((data) => {
        dispatch(login(data));
      })
      .catch(() => {
        dispatch(logout());
      });
  };

  useEffect(() => {
    signIn().catch((e) => console.error(e));
  }, []);

  return (
    <>
      <Head>
        <title>{title ? `${title} | IVCA` : 'IVCA'}</title>
        <meta name="description" content={description} />
        {keywords && <meta name="keywords" content={keywords} />}
        <meta property="og:title" content={ogTitle ?? title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content="320" />
        <meta property="og:image:height" content="160" />
        {url && <meta property="og:url" content={`https://ivca.in/${url}`} />}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="IVCA" />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      <Box width="100%">
        <Navbar hideTopStrip={hideTopStrip} />
        {/* <Box as="section" mt={{ xs: '90px', md: '123px' }}> */}
        <Box
          as="section"
          mt={{
            xs: hideTopStrip ? '102px' : '90px',
            md: hideTopStrip ? '123px' : '164px',
          }}
        >
          {children}
        </Box>
        {!hideFooter && <Footer />}
      </Box>
    </>
  );
};
export default Layout;
