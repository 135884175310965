import React from 'react';
import { Box, Img } from '@chakra-ui/react';
import Link from 'next/link';

const Logo: React.FC<{ homeLink?: string; src?: string }> = ({
  homeLink = '/',
  src = '/png/IVCA-Logo.png',
}) => (
  <Box width={{ xs: '160px', md: '200px' }}>
    <Link href={homeLink} aria-label="IVCA Logo">
      <Img src={src} alt="IVCA Logo" style={{ objectFit: 'contain' }} />
    </Link>
  </Box>
);

export default Logo;
