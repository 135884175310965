import { Box, Button } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import { useLevo } from '@levo-so/react';

import { logout } from '@/store/slices/userSlice';
import { useAppDispatch } from '@/store';
import LoginModal from '../modals/LoginModal';

interface ICustomButtonProps {
  text: string;
  variant: string;
  link?: string;
  btnWidth?: string;
  isLogin?: boolean;
  isLogout?: boolean;
  [x: string]: any;
}

const NavButton: React.FunctionComponent<ICustomButtonProps> = ({
  text,

  variant = 'solid',
  link,
  btnWidth,
  isLogin,
  isLogout,
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [loginModal, setLoginModal] = useState({ loginModalOpen: false });
  const onCloseLoginModal = () => {
    setLoginModal({ loginModalOpen: false });
  };
  const { membership } = useLevo();
  return (
    <Box>
      <Button
        rightIcon={<FiArrowUpRight size="15px" color="#56B6A5" />}
        py="13px"
        pl="24px"
        variant={variant}
        color="text.textViolet"
        fontSize="14px"
        fontWeight="700"
        lineHeight="21px"
        letterSpacing="0.02em"
        borderRadius="0px"
        borderWidth={variant === 'outline' ? '1.5px' : '0px'}
        h="47px"
        w={btnWidth}
        onClick={() => {
          if (link) {
            router.push(link);
          }
          if (isLogin) {
            setLoginModal({ loginModalOpen: true });
          }
          if (isLogout) {
            dispatch(logout());
            membership?.signOut().catch((e) => {
              console.error(e);
            });
          }
        }}
      >
        {text}
      </Button>
      <LoginModal
        isOpen={loginModal.loginModalOpen}
        onClose={onCloseLoginModal}
      />
    </Box>
  );
};

export default NavButton;
